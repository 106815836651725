<template>
  <b-row
    :id="`card-stack-${dataList.header[0].text.replaceAll(' ', '-')}`"
    align-v="center"
    align-h="center"
    style="margin: 0px; overflow: hidden"
  >
    <b-col lg="10" md="11" cols="11">
      <div class="card-stack">
        <div
          :id="`card-stack-left-${dataList.header[0].text.replaceAll(
            ' ',
            '-'
          )}`"
          :class="`img-card-stack invisible`"
          :style="{
            backgroundImage: `url(${dataList.img.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        ></div>
        <div
          :id="`card-stack-right-${dataList.header[0].text.replaceAll(
            ' ',
            '-'
          )}`"
          class="card-text invisible"
        >
          <h2>
            <span class="border-red tepa-h2">
              {{ dataList.header[0].text }}
            </span>
          </h2>

          <div class="space-tepa-p">
            <prismic-rich-text class="tepa-p" :field="dataList.info" />
          </div>

          <span
            :id="`card-stack-readmore-${dataList.header[0].text.replaceAll(
              ' ',
              '-'
            )}`"
            v-if="dataList.readmore"
            class="section-link invisible"
            @click="toPage(dataList.link[0].text)"
            >+ Read more</span
          >
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import $ from "jquery";
export default {
  props: ["dataList"],
  mounted() {
    const me = this;
    if (me.dataList.show) {
      const id = me.dataList.header[0].text.replaceAll(" ", "-");
      $(document).scroll(function () {
        const height = $(window).scrollTop() + $(window).height();
        const isMobile = $(window).width() < 992;
        if ($(`#card-stack-${id}`).length > 0) {
          if (height > $(`#card-stack-left-${id}`).offset().top) {
            $(`#card-stack-left-${id}`)
              .addClass("animate__animated animate__fadeInLeft")
              .removeClass("invisible");
          }
          if (height > $(`#card-stack-right-${id}`).offset().top) {
            $(`#card-stack-right-${id}`)
              .addClass("animate__animated animate__fadeInRight")
              .removeClass("invisible");
          }

          if ($(`#card-stack-readmore-${id}`).length > 0) {
            if (height > $(`#card-stack-readmore-${id}`).offset().top) {
              $(`#card-stack-readmore-${id}`)
                .addClass("animate__animated animate__flipInX")
                .removeClass("invisible");
            }
          }
        }
      });
    }
  },
  computed: {},
  methods: {
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>
<style scoped>
.card-stack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 90px 0;
}
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
}
.section-link {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #740b1b;
}
.img-card-stack {
  height: 653px;
  width: 660px;
}
.card-text {
  text-align: center;
  width: 600px;
  min-width: 600px;
  min-height: 750px;
  background-color: #f8f8f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 95px;
}
.space-tepa-p {
  margin: 30px 0 40px;
}

@media only screen and (max-width: 992px) {
  .card-stack {
    margin: 40px 0;
    flex-direction: column;
  }
  .space-tepa-p {
    margin: 30px 0 26px;
  }
  .card-text {
    order: 1;
    width: auto;
    min-height: 0px;
    min-width: 0px;
    padding: 40px 21px;
  }
  .img-card-stack {
    width: 100%;
    height: 256px;
    order: 2;
  }
}
</style>