var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticStyle:{"margin":"0px","overflow":"hidden"},attrs:{"id":("card-stack-" + (_vm.dataList.header[0].text.replaceAll(' ', '-'))),"align-v":"center","align-h":"center"}},[_c('b-col',{attrs:{"lg":"10","md":"11","cols":"11"}},[_c('div',{staticClass:"card-stack"},[_c('div',{class:"img-card-stack invisible",style:({
          backgroundImage: ("url(" + (_vm.dataList.img.url) + ")"),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }),attrs:{"id":("card-stack-left-" + (_vm.dataList.header[0].text.replaceAll(
          ' ',
          '-'
        )))}}),_c('div',{staticClass:"card-text invisible",attrs:{"id":("card-stack-right-" + (_vm.dataList.header[0].text.replaceAll(
          ' ',
          '-'
        )))}},[_c('h2',[_c('span',{staticClass:"border-red tepa-h2"},[_vm._v(" "+_vm._s(_vm.dataList.header[0].text)+" ")])]),_c('div',{staticClass:"space-tepa-p"},[_c('prismic-rich-text',{staticClass:"tepa-p",attrs:{"field":_vm.dataList.info}})],1),(_vm.dataList.readmore)?_c('span',{staticClass:"section-link invisible",attrs:{"id":("card-stack-readmore-" + (_vm.dataList.header[0].text.replaceAll(
            ' ',
            '-'
          )))},on:{"click":function($event){return _vm.toPage(_vm.dataList.link[0].text)}}},[_vm._v("+ Read more")]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }